<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form
      @submit.prevent="accepted"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="success" size="40">
            fa-regular fa-circle-check
          </v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <h3 class="success--text">Sua solicitação foi resolvida?</h3>
          <p>Informe seu nível de satisfação</p>
          <v-input :value="rating" :rules="[required]">
            <v-rating
              id="rating"
              name="rating"
              hover
              large
              color="yellow darken-3"
              v-model="rating"
              :background-color="valid ? 'grey' : 'error'"
            />
          </v-input>
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="success"
            title="Cancelar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            color="success"
            title="Aceitar"
            type="submit"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    rating: null
  }),

  methods: {
    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    async accepted() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const supportService = new SupportService();
        const { status } = await supportService.postFinalizeTicket({
          rating: this.rating,
          ticketId: this.ticketId
        });

        if (status === 204) {
          showMessage('success', 'Chamado finalizado com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
